@import './variable.less';

#root {
  height: 100vh;
}

//=========================================
/********** margin padding function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 8, 12, 16, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }

    .gap-@{value} {
      gap: ~'@{value}px';
    }
  }
}

.make-space();

//=========================================
/********** margin padding function end here **************/
//=========================================
.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen767 {
    display: block;
  }
}

.wrapper {
  padding: 8px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media @screen991 {
    font-size: 8px;
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
  }

  /*antd table sorter*/
  .ant-table-wrapper .ant-table-column-sorter-up,
  .ant-table-wrapper .ant-table-column-sorter-down {
    color: rgb(145, 145, 145);
  }

  .ant-table-wrapper .ant-table-column-sorter-up.active,
  .ant-table-wrapper .ant-table-column-sorter-down.active {
    color: @lw-red;
  }

  /*antd table sorter end here*/
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters,
  .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: @color-primary;
    color: @white-color;
    text-align: center;
  }
}

.table-card-page {
  height: 100%;

  .ant-table-wrapper .ant-table-cell-fix-left {
    @media @screen767 {
      position: inherit !important; //override antd inline css
    }
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    position: sticky;
    top: 0px;
    z-index: 9;
  }

  .ant-card {
    .ant-card-head {
      @media @screen767 {
        padding: 0 16px;
      }
    }

    .ant-card-head-wrapper {
      @media @screen767 {
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 10px;
      }

      .ant-card-head-title {
        @media @screen767 {
          flex: 0 0 100%;
        }
      }

      .ant-card-extra {
        @media @screen767 {
          width: 100%;
        }
      }
    }

    .ant-card-body {
      height: 100%;

      .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .ant-table {
              flex: 1;
            }
          }
        }
      }
    }
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    border-top: 1px solid @pagination-border;
    margin: 0;
    padding: 16px 0;
  }

  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0;
  }

  .ant-body-scroll > .ant-card-body .card-body-wrapper {
    padding: 0;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child,
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-radius: 0;
  }

  .ant-pagination .ant-pagination-item-active {
    background: @color-primary;
    color: @white-color;

    a {
      color: @white-color;
    }
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-item,
  .ant-pagination.ant-pagination-mini .ant-pagination-next,
  .ant-pagination.ant-pagination-mini .ant-pagination-prev {
    margin: 0 5px;
  }

  .ant-pagination.ant-pagination-mini
    .ant-pagination-item:not(.ant-pagination-item-active) {
    border: 1px solid @mobile-input;

    a {
      color: @color-primary;
      font-weight: 500;
    }
  }

  .ant-checkbox-checked {
    &:hover {
      .ant-checkbox-inner {
        //important is used to overwrite default css
        background-color: @verified-color !important;
      }
    }
    .ant-checkbox-inner {
      background-color: @verified-color;
      border-color: @white-color;
    }
  }

  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    border: 1px solid @mobile-input;
  }

  .ant-pagination.ant-pagination-mini
    .ant-pagination-next
    .ant-pagination-item-link {
    border: 1px solid @mobile-input;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-inline-start: 5px;

    @media @screen767 {
      display: block;
    }
  }

  .ant-table-wrapper .ant-table.ant-table-small {
    font-size: 12px;
    font-weight: 600;
    overflow: auto;
    color: @color-primary;
  }

  .ant-table-wrapper .ant-table {
    border-radius: 0;
  }

  .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th:nth-last-child(2) {
    border-inline-end: 0;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

.header-wrapper {
  .d-flex;
  .justify-between;
  .align-center;
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
    segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .d-flex;
  .align-center;
  .justify-center;
  color: @color-primary;

  img {
    height: 32px;
  }
}

.delete-icon {
  color: rgb(233, 36, 36);
  cursor: pointer;
}

/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/*sidebar custom less start here*/

.responsive-logo img {
  width: 44px;
}

.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}

.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  @media @screen767 {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}

.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-layout-header {
  @media @screen767 {
    padding: 0 16px !important;
  }
}

header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 16px;
  color: #545454;
  line-height: 64px;
  background: #fff;
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

:hover::-webkit-scrollbar-thumb {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  .ant-card-head {
    position: relative;
    z-index: 1;
    margin: 0;
  }

  .ant-page-header-heading {
    min-height: 48px;
  }

  > .ant-card-body {
    flex: 1 1;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .card-body-wrapper {
      height: 100%;
      overflow: auto;
      padding: 24px;
    }
  }
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}

.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;

  svg {
    margin-right: 5px;
    font-size: 16px;
  }
}

/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}

/*fleg dropdown css end here*/

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: @row-hover-color;
}

.custom-error-message {
  color: red;
  font-size: small;
}

.ant-table .ant-table-tbody .cell-warning-icon {
  font-size: 14px;
}
